import React, { useEffect, useState } from "react";
import "./index.css";

import AppRouter from "routes/AppRouter";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//browser incompatiblity
const IncompatibleBrowser = () => {
  return (
    <div className="Incompatible_div">
      <h1>Sorry, This Website is Incompatible with Your Browser</h1>
      <p>Please use Google Chrome to access this website.</p>
    </div>
  );
};

/* Comment for new branch */

export default function App() {
  const [compatibleBrowser, setCompatibleBrowser] = useState(true);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    // const isChrome =
    //   /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);
    const isEdgeBrowser = userAgent.indexOf("Edg") > -1;
    // if (!isChrome) {
    //   setCompatibleBrowser(false);
    // }
    if (isEdgeBrowser) {
      setCompatibleBrowser(false);
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* <AppRouter /> */}
      {compatibleBrowser ? <AppRouter /> : <IncompatibleBrowser />}
    </>
  );
}
