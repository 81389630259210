import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    const params = {};
    for (const [key, value] of searchParams) {
      params[key] = value;
    }
    setQueryParams(params);
  }, [searchParams]);

  const setQueryParam = (key, value) => {
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.set(key, value);
      return newSearchParams;
    });
  };

  const removeQueryParam = (key) => {
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.delete(key);
      return newSearchParams;
    });
  };

  return { queryParams, setQueryParam, removeQueryParam };
};

export default useQueryParams;
